// All of Vue plugins and globals

import Vue from "vue";
import Vuex from "vuex";
import Vuelidate from "vuelidate";
import VueProgressBar from "vue-progressbar";
import VueTimeago from "vue-timeago";

import vClickOutside from "v-click-outside";
import VueWorker from "vue-worker";
import VueTippy from "vue-tippy";

import Toast from "./components/Toast.vue";
import Datatable from "./components/DataTable/Datatable.vue";
import Navbar from "./components/Navbar/Navbar.vue";
import EditButton from "./components/EditButton.vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import StatusButton from "./components/StatusButton.vue";
import PublisherDetails from "./components/PublisherDetails.vue";
import AdvertiserDetails from "./components/AdvertiserDetails.vue";

import ValidatedSelect from "@/components/Form/ValidatedSelect.vue";
import ValidatedInput from "@/components/Form/ValidatedInput.vue";
import ValidatedTextarea from "@/components/Form/ValidatedTextarea.vue";

import config from "./config";
import httpClient from "./httpClient";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "2px",
});

Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(vClickOutside);
Vue.use(VueWorker);

Vue.use(VueTippy);

Vue.use(VueTimeago, { name: "Timeago", locale: "en" });

Vue.component("toast", Toast);
Vue.component("navbar", Navbar);
Vue.component("datatable", Datatable);
Vue.component("breadcrumbs", Breadcrumbs);
Vue.component("publisher-details", PublisherDetails);
Vue.component("advertiser-details", AdvertiserDetails);

Vue.component("status-button", StatusButton);
Vue.component("edit-button", EditButton);

Vue.component("validated-input", ValidatedInput);
Vue.component("validated-select", ValidatedSelect);
Vue.component("validated-textarea", ValidatedTextarea);

Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.prototype.$http = httpClient;

Vue.prototype.$user = JSON.parse(localStorage.getItem("user"));

Vue.filter("formatDate", function (value) {
  if (value) {
    return new Date(value).toISOString();
  }
});

Vue.filter("date", function (value) {
  if (value) {
    return new Date(value).toDateString();
  }
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("dimension_name", function (value) {
  return value
    .split(".")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
});

Vue.mixin({
  methods: {
    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to = "aaaaeeeeiiiioooouuuunc------";

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },
});

Vue.prototype.$config = config.appConfig;

export default Vue;
