<template>
  <nav
    class="flex flex-wrap items-center justify-between max-w-full md:py-2 py-6 px-4 space-x-3 shadow bg-publicis-500"
  >
    <div class="flex-shrink-0">
      <router-link to="/home">
        <picture style="width: 4rem">
          <source srcset="/assets/img/stories-logo.webp" type="image/webp" />
          <source srcset="/assets/img/stories-logo.png" type="image/png" />
          <img src="/assets/img/stories-logo.png" class="w-16" />
        </picture>
      </router-link>
    </div>
    <div class="block lg:hidden">
      <button
        class="flex items-center px-3 py-2 text-white border border-white rounded hover:text-white hover:border-white"
        @click="responsiveMenu = !responsiveMenu"
      >
        <svg class="w-3 h-3 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <div
      v-show="responsiveMenu"
      class="block w-full lg:w-auto lg:flex lg:items-center lg:flex-grow lg:space-x-8 lg:justify-between"
    >
      <div class="text-sm lg:flex">
        <navbar-dropdown
          title="Publisher"
          v-if="[1, 3].includes(user.view_id)"
          :items="publisherItems"
        />
        <navbar-dropdown title="Advertiser" v-show="user.view_id < 3" :items="advertiserItems" />
        <navbar-dropdown title="Reporting" :link="{ name: 'Reporting' }" :items="reportingItems" />
      </div>
      <div
        class="relative flex-grow hidden text-gray-600 rounded-full shadow lg:flex lg:items-center lg:w-auto focus:shadow-lg"
      >
        <input
          class="w-full h-8 px-5 pr-16 text-sm bg-white border-gray-300 rounded-full border-1 focus:outline-none"
          type="search"
          name="search"
          placeholder="Search"
        />
        <button
          type="submit"
          class="absolute top-0 right-0 mt-1 mr-3 cursor-pointer hover:bg-gray-200 rounded-full p-1"
        >
          <svg
            class="w-3 h-3 text-gray-600 fill-current"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>
      <dropdown class="ml-auto text-center">
        <template slot="button">
          <div
            class="flex items-center p-4 text-white rounded cursor-pointer lg:justify-center hover:bg-publicis-200 hover:text-white"
            id="userInfo"
          >
            <div class="flex-grow mr-4">
              <img src="/assets/icons/icon-cog.svg" alt />
            </div>

            <div v-if="$store.getters['auth/authenticatedUser'].firebase.photoURL">
              <img
                class="block w-6 h-6 rounded-full"
                :src="$store.getters['auth/authenticatedUser'].firebase.photoURL"
                alt=""
              />
            </div>
          </div>
        </template>
        <template slot="inner">
          <div class="z-50 flex flex-col bg-gray-200 rounded-b-sm cursor-pointer" id="options">
            <!-- <div class="px-4 py-2 hover:bg-gray-300">
              <p class="text-sm">{{ user.name }}</p>
            </div> -->
            <!-- <div class="py-2 hover:bg-gray-200">
          <router-link :to="{name: 'Forum' }">Forum</router-link>
          </div>-->
            <div class="px-4 py-2 hover:bg-gray-300" v-show="user.role_id < 3">
              <router-link :to="{ name: 'Account' }">Account Management</router-link>
            </div>
            <div class="px-4 py-2 hover:bg-gray-300" v-show="user.role_id < 3">Impersonate</div>
            <div class="px-4 py-2 hover:bg-gray-300">
              <router-link :to="{ name: 'Logout' }">LogOut</router-link>
            </div>
          </div>
        </template>
      </dropdown>
    </div>
  </nav>
</template>

<script>
import NavbarDropdown from "./NavbarDropdown";
import Dropdown from "../Dropdown";

export default {
  name: "navbar",
  components: { NavbarDropdown, Dropdown },
  data() {
    return {
      publisherItems: [
        { item: "Sites", link: { name: "Sites" } },
        { item: "Placements", link: { name: "Placements" } },
        { item: "Library", link: { name: "Library" } },
        { item: "Catalog", link: { name: "PCatalogs" } },
      ],
      advertiserItems: [
        { item: "Advertisers", link: { name: "Advertisers" } },
        {
          item: "Campaigns",
          link: { name: "Campaigns" },
        },
        { item: "Line Items", link: { name: "LineItems" } },
        { item: "Creatives", link: { name: "Creatives" } },
        { item: "Catalog", link: { name: "ACatalogs" } },
      ],
      reportingItems: [],
      responsiveMenu: window.screen.width > 1024 ? true : false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/authenticatedUser"].laravel;
    },
  },
  // mounted() {
  //   if (this.user.role_id === 1) {
  //     this.publisherItems.unshift({
  //       item: "Publishers",
  //       link: { name: "Publishers" },
  //     });
  //     this.advertiserItems.unshift({
  //       item: "Agencies",
  //       link: { name: "Agencies" },
  //     });
  //   }
  // },
};
</script>
