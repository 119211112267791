import LocalStorage from "../services/LocalStorage";
import httpClient from "../httpClient";

const auth = {
  namespaced: true,
  state: {
    user: LocalStorage.getUser(),
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      LocalStorage.writeUser(user);
      if (user === {} || !user) {
        LocalStorage.clearUser();
      }
    },
    UPDATE_USER(state, user) {
      state.user = { ...state.user, ...user };
    },
  },
  actions: {
    setUser({ commit }, data) {
      commit("SET_USER", data);
    },
    removeUser({ commit }) {
      commit("SET_USER", {});
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.trim().split("=")[0] + "=;" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      });
      httpClient.post("api/logout");
    },
    updateLaravelUser({ commit }, data) {
      commit("UPDATE_USER", { laravel: data });
    },
  },
  getters: {
    authenticatedUser: (state) => state.user,
    isAuthenticated: (state) => !!state,
    laravelUser: (state) => state.user.laravel,
  },
};

export default auth;
