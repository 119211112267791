import Account from "@/views/Users/Account.vue";

const adminRoutes = [
  {
    path: "",
    name: "Account",
    component: Account,
    meta: { requiresAuth: true },
  },
];

export default adminRoutes;
