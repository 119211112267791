<template>
  <div>
    <div v-if="toast.show">
      <transition name="slide-fade">
        <div class="fixed right-0 z-50 mt-4 mr-12">
          <toast :title="toast.title" :text="toast.text"></toast>
        </div>
      </transition>
    </div>
    <modal @hide="modal.showModal = false" ref="modal" v-if="modal.showModal" class="w-5/12">
      <div class="flex">
        <input class="hidden" id="id" type="text" v-model="modal.info.id" />
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="name"
            >Name</label
          >
          <input
            class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-500 focus:border-4"
            id="name"
            type="text"
            v-model="modal.info.name"
          />
        </div>
      </div>
      <div class="flex">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="email"
            >EMAIL</label
          >
          <input
            class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-500 focus:border-4"
            id="email"
            type="text"
            v-model="modal.info.email"
          />
        </div>
      </div>
      <div
        class="flex justify-center"
        v-if="typeof modal.info.id == 'undefined' || modal.info.id == null"
      >
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="password"
            >PASSWORD</label
          >
          <div class="flex">
            <input
              class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-500 focus:border-4"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              v-model="modal.info.password"
            />
            <div
              class="flex flex-col justify-center w-4 mx-2 mb-3 cursor-pointer"
              @click="passwordView()"
            >
              <img src="/assets/icons/icon-lock-closed.svg" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-full px-3 mb-6 md:w-6/12 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="account"
            >ACCOUNT</label
          >
          <div class="relative">
            <select
              name="account"
              id="account"
              class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              :value="
                $user.laravel.accounts.length > 1
                  ? this.modal.info.account_id
                  : $user.laravel.account_id
              "
              :disabled="!($user.laravel.accounts.length > 1)"
            >
              <option v-for="account in accounts" :key="account.id" :value="account.id">
                {{ account.name }}
              </option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <svg
                class="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="w-full px-3 mb-6 md:w-6/12 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="status"
            >Status</label
          >
          <div class="relative">
            <select
              name="status"
              id="status"
              class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              v-model="modal.info.status"
            >
              <option v-for="status in statuses" :key="status.id" :value="status.id">
                {{ status.name }}
              </option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <svg
                class="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center pt-4">
        <div class="w-full px-3 mb-6 md:w-6/12 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="account"
            >VIEW</label
          >
          <div class="relative">
            <select
              name="view"
              id="view"
              class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              v-model="modal.info.view_id"
            >
              <option v-for="view in views" :key="view.id" :value="view.id">{{ view.name }}</option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <svg
                class="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="w-full px-3 mb-6 md:w-6/12 md:mb-0">
          <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="account"
            >ROLE</label
          >
          <div class="relative">
            <select
              name="role"
              id="role"
              class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              v-model="modal.info.role_id"
            >
              <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <svg
                class="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center pt-4 pb-2">
        <div>
          <button
            class="px-2 py-1 text-sm text-white bg-teal-500 border border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700 focus:outline-none focus:border-8 focus:border-teal-700 focus:bg-teal-400"
            type="submit"
            @click="saveUser()"
          >
            Save
          </button>
        </div>
        <div class="flex justify-center pl-8">
          <button
            class="px-2 py-1 text-sm text-white bg-red-500 border border-red-500 rounded hover:bg-red-700 hover:border-red-700 focus:outline-none focus:border-8 focus:border-red-700 focus:bg-red-400"
            type="button"
            @click="cancelModal()"
          >
            Cancel
          </button>
        </div>
      </div>
    </modal>

    <tailwind-modal :showing="showInstagramModal" @close="cancelInstagramModal()">
      <form @submit.prevent="getInstagramExamples()">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label
            class="block mb-1 text-xs font-bold text-gray-700 uppercase"
            for="instagram_profileName"
            >Name</label
          >
          <input
            class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-gray-100 focus:border-teal-100 focus:border-2"
            id="instagram_profileName"
            type="text"
            v-model="instagram.name"
          />
        </div>

        <div v-if="instagram.stories.length > 0">
          <div>
            <h1>Are these you stories ?</h1>
          </div>
          <div class="grid grid-cols-3 gap-3">
            <div v-for="(story, index) in instagram.stories" :key="index">
              <instagram-story :story="story" ref="story"></instagram-story>
            </div>
          </div>
        </div>
        <button
          class="px-2 py-1 text-sm text-white bg-teal-500 border border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700"
          type="submit"
        >
          {{ instagram.stories.length > 0 ? "Confirm" : "Save" }}
        </button>
      </form>
    </tailwind-modal>

    <breadcrumbs class="mx-8 my-4" raw="Accounts"></breadcrumbs>
    <div class="mx-16">
      <div v-if="preload">
        <div v-for="i in this.$user.laravel.accounts" :key="i" class="p-4 mb-4">
          <preload class="w-full h-12"></preload>
        </div>
      </div>
      <div v-for="account in this.accounts" :key="account.name">
        <account-details
          :account="account"
          :currencies="currencies"
          :timezones="timezones"
          @showInstagram="showInstagram($event)"
          @showEditUser="showUserModal($event)"
          @showNewUser="showUserModal($event)"
          @deleteUser="deleteUser(account.id, $event)"
        ></account-details>
      </div>
    </div>
  </div>
</template>

<script>
import TailwindModal from "@/components/TailwindModal.vue";
import Modal from "@/components/Modal.vue";
import Preload from "@/components/Preload.vue";
import AccountDetails from "@/components/Account/Details.vue";
import InstagramStory from "@/components/Creative/InstagramStory";

export default {
  components: {
    "account-details": AccountDetails,
    preload: Preload,
    modal: Modal,
    "tailwind-modal": TailwindModal,
    "instagram-story": InstagramStory,
  },
  data() {
    return {
      showPassword: false,
      showInstagramModal: false,
      instagram: {
        loading: false,
        stories: [],
        name: "",
        account_id: null,
      },
      modal: {
        showModal: false,
        info: {
          name: null,
          account_id: null,
          email: null,
          role_id: null,
          status: null,
          view_id: null,
        },
      },
      toast: {
        show: false,
        title: "",
        text: "",
      },
      preload: true,
      accounts: [],
      currencies: [],
      timezones: [],
      views: [],
      roles: [],
      statuses: [],
    };
  },
  created() {
    if (this.$user.laravel.accounts.length > 0) {
      this.$http
        .get(`${this.$config.base_url}api/accounts`)
        .then((resp) => {
          this.accounts = resp.data.accounts.data || resp.data.accounts;
          this.currencies = resp.data.currencies;
          this.timezones = resp.data.timezones;
          this.views = resp.data.views;
          this.roles = resp.data.roles;
          this.statuses = resp.data.statuses;
          this.preload = false;
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    showUserModal(e) {
      if (e.action == "new") {
        this.modal.showModal = true;
        this.modal.info = {
          name: null,
          account_id: null,
          email: null,
          role_id: null,
          status: null,
          view_id: null,
        };
        this.modal.info.account_id = e.account_id;
      } else if (e.action == "edit") {
        this.modal.showModal = true;
        this.modal.info = Object.assign(this.modal.info, e.user);
      }
    },
    showInstagram(e) {
      this.instagram.account_id = e.account_id;
      this.showInstagramModal = true;
    },
    async cancelInstagramModal() {
      this.instagram.name = "";
      this.instagram.stories = [];
    },
    async getInstagramExamples() {
      this.instagram.loading = true;
      const { data } = await this.$http.get("api/creatives/instagram/setupProfile", {
        params: { account_id: this.instagram.account_id, name: this.instagram.name },
      });
      this.instagram.loading = false;
      this.instagram.stories = data;
    },
    cancelModal() {
      this.modal.showModal = false;
    },
    showToast(mode) {
      this.toast.title = "Succesful";
      if (mode === "create") {
        this.toast.text = "User created succesfully";
      } else if (mode === "edit") {
        this.toast.text = "User edited succesfully";
      }

      this.toast.show = true;
      setTimeout(() => {
        this.toast.show = false;
      }, 2500);
    },
    saveUser() {
      if (typeof this.modal.info.id == "undefined" || this.modal.info.id == null) {
        this.$http
          .post(`${this.$config.base_url}api/users`, this.modal.info)
          .then((resp) => {
            const account = this.accounts.findIndex(() => this.modal.info.account_id);

            this.accounts[account].users.push(resp.data.laravel);

            this.modal.showModal = false;
            this.showToast("create");
          })
          .catch((err) => console.error(err));
      } else {
        this.$http
          .put(`${this.$config.base_url}api/users/${this.modal.info.id}`, this.modal.info)
          .then((resp) => {
            for (var [i, account] of this.accounts.entries()) {
              if (account.id == this.modal.info.account_id) {
                this.accounts[i] = resp.data.account;
              }
            }
            this.modal.showModal = false;
            this.showToast("edit");
          })
          .catch((err) => console.error(err));
      }
    },
    deleteUser(accountId, userId) {
      for (var [i, account] of this.accounts.entries()) {
        if (account.id == accountId) {
          this.accounts[i].users = this.accounts[i].users.filter((u) => u.id != userId);
        }
      }
    },
    passwordView() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.25s ease-in;
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
