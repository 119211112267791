import Vue from "vue";
import Router from "vue-router";

import publisherRoutes from "@/router/publisher";
import advertiserRoutes from "@/router/advertiser";
import adminRoutes from "@/router/admin";
import forumRoutes from "@/router/forum";
import reportingRoutes from "@/router/reporting";

import { checkInDB } from "@/services/LoginService";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("@/views/Logout.vue"),
    },
    {
      path: "/preview/:placementId",
      name: "Preview",
      component: () => import("@/views/Preview.vue"),
    },
    {
      path: "/publisher",
      component: () => import("@/views/layouts/Publisher.vue"),
      children: publisherRoutes,
      meta: {
        side: "publisher",
      },
    },
    {
      path: "/advertiser",
      component: () => import("@/views/layouts/Advertiser.vue"),
      children: advertiserRoutes,
      meta: {
        side: "advertiser",
      },
    },
    {
      path: "/admin",
      component: () => import("@/views/layouts/Default.vue"),
      children: adminRoutes,
      meta: {
        side: "admin",
      },
    },
    {
      path: "/forum",
      component: () => import("@/views/layouts/Default.vue"),
      children: forumRoutes,
      meta: {
        side: "forum",
      },
    },
    {
      path: "/reporting",
      component: () => import("@/views/layouts/Default.vue"),
      children: reportingRoutes,
      meta: {
        side: "reporting",
      },
    },
    {
      path: "/403",
      component: () => import("@/views/Errors/403.vue"),
    },
    {
      path: "*",
      component: () => import("@/views/Errors/404.vue"),
    },
  ],
});

router.afterEach(async () => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];

  if (isAuthenticated) {
    const { data } = await checkInDB();
    store.dispatch("auth/updateLaravelUser", data);
  }
});

export default router;
