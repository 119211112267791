const advertiserRoutes = [
  {
    path: "",
    component: () => import("@/views/Advertiser/Advertisers.vue"),
    name: "Advertisers",
  },
  {
    path: "create",
    component: () => import("@/views/Advertiser/NewAdvertiser.vue"),
    name: "New Advertiser",
  },
  {
    path: "campaigns",
    component: () => import("@/views/Campaign/Campaigns.vue"),
    name: "Campaigns",
  },
  {
    path: "campaigns/new",
    component: () => import("@/views/Campaign/NewCampaign.vue"),
    name: "New Campaign",
  },
  {
    path: "campaigns/:id",
    component: () => import("@/views/Campaign/NewCampaign.vue"),
    name: "Edit Campaign",
  },
  {
    path: "lineitems",
    component: () => import("@/views/LineItem/LineItems.vue"),
    name: "LineItems",
  },
  {
    path: "lineitems/new",
    component: () => import("@/views/LineItem/NewLineItem.vue"),
    name: "New LineItem",
  },
  {
    path: "lineitems/:id",
    component: () => import("@/views/LineItem/NewLineItem.vue"),
    name: "Edit LineItem",
  },
  {
    path: "agencies",
    component: () => import("@/views/Agency/Agencies.vue"),
    name: "Agencies",
  },
  {
    path: "creatives",
    component: () => import("@/views/Creatives/Creatives.vue"),
    name: "Creatives",
  },
  {
    path: ":advertiserId",
    component: () => import("@/views/Advertiser/NewAdvertiser.vue"),
    name: "Edit Advertiser",
  },
  {
    path: "creatives/new",
    component: () => import("@/views/Creatives/NewCreative.vue"),
    name: "New Creative",
  },
  {
    path: "creatives/:creativeId",
    component: () => import("@/views/Creatives/NewCreative.vue"),
    name: "Edit Creative",
  },
  {
    path: "catalogs",
    component: () => import("@/views/Catalog/ACatalog.vue"),
    name: "ACatalogs",
  },
  {
    path: "catalogs/new",
    component: () => import("@/views/Catalog/ANewCatalog.vue"),
    name: "New ACatalogs",
  },
  {
    path: "catalogs/:id",
    component: () => import("@/views/Catalog/ANewCatalog.vue"),
    name: "Edit ACatalogs",
  },
];

advertiserRoutes.forEach((x) => (x.meta = { side: "advertiser" }));

export default advertiserRoutes;
