<template>
  <div
    class="fixed top-0 left-0 w-full h-screen overflow-y-hidden z-10 flex justify-center"
    id="background"
  >
    <div class="flex flex-col justify-center w-4/12" v-click-outside="clickOutside">
      <div class="bg-white rounded shadow pt-1 pr-1 pb-2 pl-2">
        <img
          @click="$emit('hide')"
          id="rotate"
          class="ml-auto cursor-pointer rounded-full hover:bg-gray-200"
          src="/assets/icons/icon-plus.svg"
          alt="Close"
        />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.$emit("hide");
      }
    });
  },
  methods: {
    clickOutside() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
#background {
  background-color: #00000082;
}

#rotate {
  transform: rotate(45deg);
}
</style>
