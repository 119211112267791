// Default init for Firebase.

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";

import config from "./config";

firebase.initializeApp(config.firebaseConfig);
firebase.performance();

export default firebase;
