<template>
  <div class="container mx-auto">
    <div>
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none mt-4"
        @click="newPost.show = true"
      >
        New Post
      </button>
    </div>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
      <new-post
        @cancel="newPost.show = false"
        @create="createPost($event)"
        v-if="newPost.show"
        ref="newPost"
      ></new-post>
    </transition>
    <post v-for="(post, index) in posts" :key="index" :post="post" class="mt-4"></post>
  </div>
</template>
<script>
import Post from "@/components/Forum/Post.vue";
import NewPost from "@/components/Forum/NewPost.vue";
import Dropdown from "@/components/Dropdown.vue";

export default {
  components: {
    "new-post": NewPost,
    post: Post,
    dropdown: Dropdown,
  },
  data() {
    return {
      documents: [],
      forum: ForumService.getInstance(),
      posts: [],
      newPost: {
        show: false,
      },
    };
  },
  created() {
    this.forum.subscribe("posts", (x) => {
      this.posts = x.val();
    });
  },
  methods: {
    createPost(e) {
      this.forum.writeInfo(`posts/${this.forum.randomId()}`, {
        ...e,
        timestamp: Date.now(),
        user: {
          uid: this.$user.firebase.uid,
          name: this.$user.laravel.name,
          email: this.$user.laravel.email,
        },
        comments: [],
      });
      this.newPost.show = false;
      this.$refs.newPost.post_content = "";
    },
  },
};
</script>
<style lang="scss" scoped>
#post:not(:last-child) {
  margin-bottom: 1rem;
}
#absolute {
  top: 10%;
  right: 5%;
}
</style>
