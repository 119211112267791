<template>
  <div
    class="py-4 rounded flex"
    :class="{
      'bg-red-200': post.status == 0,
      'bg-green-200': post.status == 1,
      'bg-gray-200': post.status == 2,
    }"
  >
    <div class="justify-left flex flex-col justify-center ml-4">
      <div>
        <h1 class="font-bold">{{ post.title }} | Priority => {{ post.priority }}</h1>
      </div>
    </div>
    <div class="ml-auto flex">
      <div class="rounded-full hover:bg-gray-200 cursor-pointer p-2">
        <img src="/assets/icons/icon-checkmark.svg" alt />
      </div>
      <router-link :to="{ name: 'PostDetails', params: { id: this.$vnode.key } }">
        <div class="rounded-full hover:bg-gray-200 cursor-pointer mr-4 p-2">
          <img src="/assets/icons/icon-edit.svg" alt />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: ["post"],
});
</script>

<style></style>
