import Vue from "./vue";
import store from "./store";
import firebase from "./firebase";

import router from "./router";

import App from "./App.vue";
import "./assets/sass/main.scss";

firebase.auth().onAuthStateChanged(() => {
  /* eslint-disable no-new */
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
});

