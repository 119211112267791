import httpClient from "@/httpClient";

export const checkInDB = function () {
  return httpClient.get("api/currentUser");
};

export const loginLaravel = async function (email) {
  await httpClient.get("/sanctum/csrf-cookie", {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
  const { status, data } = await httpClient.post("api/login", { email: email });
  if (status === 200) {
    return data.user;
  } else {
    return false;
  }
};

export const logout = function () {
  const firebase = require("firebase/app");
  require("firebase/auth");
  Promise.allSettled([firebase.auth().signOut(), httpClient.post("api/logout")]).then(
    () => (window.location.pathname = "/login")
  );
};
