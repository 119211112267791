<template>
  <div class="flex flex-col lg:w-6/12 sm:w-full rounded">
    <div class="flex justify-between">
      <label
        class="block text-gray-600 font-bold text-left mb-1 md:mb-0 p-2"
        :for="`${label}-selector`"
        v-text="label"
      />
      <div class="flex flex-col justify-end">
        <span
          v-if="v.$dirty && !v.required"
          class="text-sm p-1"
          :class="{ 'text-gray-600': v.$dirty && !v.required }"
        >
          This field is required
        </span>
      </div>
    </div>
    <div class="pb-2 px-2 relative">
      <select
        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 p-2 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        :id="`${label}-selector`"
        v-model="val"
        @change="$emit('change', $event)"
        @select="$emit('select', $event)"
        :multiple="multiplev"
      >
        <option v-for="item in data" :key="item.id" :value="item.id">{{ item.name }}</option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 pb-1 text-gray-700"
      >
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValidatedSelect",
  props: {
    v: { type: Object, required: true },
    value: { type: [String, Number, Array], default: 1 },
    multiplev: { type: [Boolean], default: false },
    data: {
      type: [Array, undefined],
      inmediate: true,
      handler(newVal, oldVal) {
        const found = newVal.find((x) => x.id === this.val);
        if (!found) {
          this.val = newVal[0].id;
        }
      },
    },
    label: { type: String, default: "Field" },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit("input", newval);
      },
    },
  },
};
</script>

<style scoped></style>
