<template>
  <div class="flex flex-col">
    <h3 class="font-bold">Buttons</h3>
    <div v-for="line in data" :key="line.id">
      <div class="flex justify-between space-x-1 md:space-x-3 buttons">
        <!-- <status-button
          :field="line.status"
          :link="options.status.link.replace('$id$', line.id)"
          @status="$emit('status', $event)"
        /> -->
        <edit-button :to="options.edit.link.replace('$id$', line.id)" />
        <preview-button
          v-if="options.preview"
          :to="options.preview.link.replace('$id$', line.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewButton from "@/components/PreviewButton";

export default {
  name: "ActionButtons",
  props: ["data", "options", "model"],
  components: {
    PreviewButton,
  },
};
</script>

<style scoped>
.buttons {
  padding: 3.1px 0;
}
</style>
