<template>
  <div class="relative h-full" @click.prevent="clickStory()">
    <img :src="story.resource" alt="" v-if="!story.is_video" />
    <video controls v-else-if="story.is_video">
      <source :src="story.resource" type="video/mp4" />
    </video>
    <div class="bg-red-500 absolute h-full w-full inset-0 opacity-25" v-if="deleted"></div>
    <div class="bg-green-500 absolute h-full w-full inset-0 opacity-25" v-if="isSelected"></div>
  </div>
</template>

<script>
export default {
  name: "InstagramStory",
  props: {
    story: { type: Object },
    deleting: { type: Boolean, required: false },
    selecting: { type: Boolean, required: false },
  },
  data() {
    return {
      deleted: false,
    };
  },
  computed: {
    isSelected: {
      get() {
        return !!this.story.selected;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    clickStory() {
      if (this.selecting) {
        this.isSelected = !this.isSelected;
      }
      if (this.deleting) {
        this.deleted = !this.deleted;
      }
    },
  },
};
</script>

<style scoped></style>
