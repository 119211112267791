<template>
  <transition-group name="notif">
    <notification
      :notification="notification"
      v-for="notification in notifications"
      :key="notification.uniqid"
      class="mt-4"
    />
  </transition-group>
</template>

<script>
import Notification from "./Notification";
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Notification,
  },
};
</script>

<style lang="scss" scoped>
.notif-enter-active,
.notif-leave-active {
  transition: all 0.5s;
}
.notif-enter,
.notif-leave-to {
  opacity: 0;
  transform: translateX(25px);
}
</style>
