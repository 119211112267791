<template>
  <div>
    <textarea
      name="body"
      placeholder="Comment"
      id="post"
      class="appearance-none bg-transparent border rounded border-gray-300 w-full text-gray-700 p-2 leading-tight focus:outline-none focus:border-teal-300"
      rows="5"
      v-model="body"
    ></textarea>
    <div class="text-right">
      <button
        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        @click="sendComment()"
      >
        Comment
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    post: {
      type: String,
      required: true,
    },
    comment: String,
  },
  data() {
    return {
      // forum: ForumService.getInstance(),
      body: "",
    };
  },
  methods: {
    sendComment() {
      const comment = {
        body: this.body,
        timestamp: Date.now(),
        user: {
          uid: this.$user.firebase.uid,
          name: this.$user.laravel.name,
          email: this.$user.laravel.email,
        },
        parent: this.comment || "",
      };
      this.forum.writeInfo(`posts/${this.post}/comments/${this.forum.randomId()}`, comment);
    },
  },
});
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
</style>
