<template>
  <div
    :class="{
      'bg-teal-100': type == 'success',
      'text-teal-900': type == 'success',
      'border-teal-500': type == 'success',
      'bg-red-100': type == 'error',
      'text-red-900': type == 'error',
      'border-red-500': type == 'error',
    }"
    class="border-t-4 rounded-b px-4 py-3 shadow-md"
    role="alert"
  >
    <div class="flex">
      <div class="py-1">
        <svg
          class="fill-current h-6 w-6 text-teal-500 mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
          />
        </svg>
      </div>
      <div>
        <p class="font-bold">{{ this.title }}</p>
        <p class="text-sm">{{ this.text }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    title: { type: String },
    text: { type: String },
    type: { type: String, default: "success" },
  },
});
</script>

<style></style>
