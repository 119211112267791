// Base config for axios instance.
// Binded to this.$http on the whole Vue app

import axios from "axios";
import config from "@/config";

const httpClient = axios.create({
  baseURL: config.appConfig.base_url,
  timeout: 150000,
  withCredentials: true,
});

export default httpClient;
