<template>
  <div class="flex flex-col">
    <h3 class="font-bold whitespace-no-wrap">
      {{ field.title }}
    </h3>
    <div v-for="item in data" :key="item.id">
      <div
        class="button"
        @click="gotoLink(field.link, '$id$', item.id)"
        v-if="field.customHtml"
        v-html="replacer(field.customHtml, '$field$', getDescendantProp(item, field.name))"
      ></div>
      <div v-else class="py-2">
        {{ getDescendantProp(item, field.name) ? getDescendantProp(item, field.name) : 0 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Column",
  props: ["field", "data"],
  methods: {
    getDescendantProp(obj, desc) {
      const arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    replacer(item, to_replace, replacer) {
      return item.split(to_replace).join(replacer);
    },
    gotoLink(link, replace, replacer) {
      link = link.replace("$id$", replacer);
      this.$router.push(link);
    },
  },
};
</script>

<style scoped>
.button {
  padding: 6px 0;
}
</style>
