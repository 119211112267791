const publisherRoutes = [
  {
    path: "",
    component: () => import("@/views/Publisher/Publisher.vue"),
    name: "Publishers",
  },
  {
    path: "instagram",
    component: () => import("@/views/Creatives/Instagram.vue"),
    name: "Instagram",
  },
  {
    path: "create",
    component: () => import("@/views/Publisher/NewPublisher.vue"),
    name: "NewPublisher",
  },
  {
    path: "sites",
    component: () => import("@/views/Site/Site.vue"),
    name: "Sites",
  },
  {
    path: "sites/new",
    component: () => import("@/views/Site/NewSite.vue"),
    name: "NewSite",
  },
  {
    path: "sites/:siteId",
    component: () => import("@/views/Site/NewSite.vue"),
    name: "Edit Site",
  },
  {
    path: "placements",
    component: () => import("@/views/Placements/Placements.vue"),
    name: "Placements",
  },
  {
    path: "placements/new",
    component: () => import("@/views/Placements/NewPlacement.vue"),
    name: "New Placement",
  },
  {
    path: "placements/preview/:placementId",
    component: () => import("@/views/Placements/PreviewPlacement.vue"),
    name: "Preview Placement",
  },
  {
    path: "placements/:placementId",
    component: () => import("@/views/Placements/NewPlacement.vue"),
    name: "Edit Placement",
  },
  {
    path: "library",
    component: () => import("@/views/Creatives/Creatives.vue"),
    name: "Library",
  },
  {
    path: "creatives/new",
    component: () => import("@/views/Creatives/NewCreative.vue"),
    name: "New Creative",
  },
  {
    path: "creatives/:creativeId",
    component: () => import("@/views/Creatives/NewCreative.vue"),
    name: "Edit Creative",
  },
  {
    path: ":publisherId",
    component: () => import("@/views/Publisher/NewPublisher.vue"),
    name: "Edit Publisher",
  },
  {
    path: "catalogs",
    component: () => import("@/views/Catalog/PCatalog.vue"),
    name: "PCatalogs",
  },
  {
    path: "catalogs/new",
    component: () => import("@/views/Catalog/PNewCatalog.vue"),
    name: "New PCatalogs",
  },
  {
    path: "catalogs/:id",
    component: () => import("@/views/Catalog/PNewCatalog.vue"),
    name: "Edit PCatalogs",
  },
];

publisherRoutes.forEach((x) => (x.meta = { side: "publisher" }));

export default publisherRoutes;
