var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"title":"edit"}},[_c('button',{staticClass:"px-2 py-1 text-xs text-white border rounded md:px-2 md:w-16 md:h-8 focus:outline-none focus:shadow-outline",class:{
      'bg-red-500': this.field === 0,
      'hover:bg-red-400': this.field === 0,
      'bg-green-500': this.field === 1,
      'hover:bg-green-400': this.field === 1,
      'bg-blue-500': this.field === 2,
      'hover:bg-blue-400': this.field === 2,
    },on:{"click":function($event){return _vm.statusChange()}}},[_vm._v(" "+_vm._s(this.field === 0 ? "Inactive" : this.field === 1 ? "Active" : this.field === 2 ? "Test" : "")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }