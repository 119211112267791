<template>
  <div class="py-1 rounded border border-gray-200">
    <div class="text-left pl-4">
      <span class="text-sm text-teal-700">{{
        comment.user.name || comment.user.name || comment.user.uid || "nope"
      }}</span>
      <timeago
        class="text-xs text-gray-500 pl-2"
        :datetime="new Date(comment.timestamp)"
        :title="(comment.timestamp / 1000) | formatDate"
      ></timeago>
      <!-- <span class="text-xs text-gray-500 pl-2">{{ comment.timestamp/1000 | formatDate}}</span> -->
    </div>
    <div class="text-left pl-8 pr-16">
      <p>{{ comment.body }}</p>
    </div>
    <div class="pr-8">
      <div class="flex justify-end" v-if="!newComment">
        <div
          class="flex p-1 px-2 rounded-full hover:bg-gray-300 cursor-pointer"
          @click="showNewComment()"
        >
          <img class="pr-1" src="/assets/icons/icon-chat.svg" alt />
          <span>Reply</span>
        </div>
      </div>
      <div class="flex justify-end" v-else-if="newComment">
        <div
          class="flex p-1 px-2 rounded-full hover:bg-gray-300 cursor-pointer"
          @click="hideNewComment()"
        >
          <img id="rotate" src="/assets/icons/icon-plus.svg" alt />
          <span>Cancel</span>
        </div>
      </div>
    </div>
    <div class="px-8 pt-4" v-if="newComment">
      <new-comment :post="postId" :comment="this.$vnode.key"></new-comment>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import NewComment from "./NewComment.vue";

export default Vue.extend({
  props: ["postId", "comments"],
  components: {
    "new-comment": NewComment,
  },
  data() {
    return {
      newComment: false,
    };
  },
  methods: {
    showNewComment() {
      this.newComment = true;
    },
    hideNewComment() {
      this.newComment = false;
    },
  },
});
</script>

<style lang="scss" scoped>
#rotate {
  transform: rotate(45deg);
}
</style>
