var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-t-4 rounded-b px-4 py-3 shadow-md",class:{
    'bg-teal-100': _vm.type == 'success',
    'text-teal-900': _vm.type == 'success',
    'border-teal-500': _vm.type == 'success',
    'bg-red-100': _vm.type == 'error',
    'text-red-900': _vm.type == 'error',
    'border-red-500': _vm.type == 'error',
  },attrs:{"role":"alert"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"py-1"},[_c('svg',{staticClass:"fill-current h-6 w-6 text-teal-500 mr-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"}})])]),_c('div',[_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(this.title))]),_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(this.text))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }