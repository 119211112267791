<template>
  <div id="dropdown" class="relative inline-block">
    <div @click="isOpen = true">
      <slot name="button"></slot>
    </div>
    <div v-if="isOpen" @click="isOpen = false" class="fixed inset-0" tabindex="-1"></div>
    <transition name="slide-fade">
      <div v-if="isOpen" class="mt-2 absolute right-0 origin-top-right">
        <slot name="inner"></slot>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isOpen: false,
    };
  },
});
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}
</style>
