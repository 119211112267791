<template>
  <div>
    <div
      v-show="detailsHidden"
      @click="$store.dispatch('updateHidden', false)"
      class="h-16 bg-gray-100 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
    >
      <div class="w-8 mt-3 mr-1 p-1 rounded-full">
        <svg
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="border rounded border-gray-200 max-h-full" v-show="!detailsHidden">
      <div
        class="flex pl-4 pr-1 py-4 font-bold bg-gray-100 relative hover:bg-gray-200 cursor-pointer"
        @click="$store.dispatch('updateHidden', true)"
      >
        <div>
          <h3>Advertiser Details</h3>
        </div>
        <div class="ml-auto w-4 self-center">
          <svg
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </div>
      </div>
      <div class="m-auto text-left px-6 py-2 text-center">
        <ul>
          <li class="mb-2">
            <div class="hover:bg-gray-200 p-1 rounded-sm cursor-pointer">
              <router-link :to="{ name: 'Advertisers' }">Advertisers</router-link>
            </div>
          </li>
          <li class="mb-2">
            <div class="hover:bg-gray-200 p-1 rounded-sm cursor-pointer">
              <router-link :to="{ name: 'Campaigns' }">Campaigns</router-link>
            </div>
          </li>
          <li class="mb-2">
            <div class="hover:bg-gray-200 p-1 rounded-sm cursor-pointer">
              <router-link :to="{ name: 'LineItems' }">LineItems</router-link>
            </div>
          </li>
          <li>
            <div class="hover:bg-gray-200 p-1 rounded-sm cursor-pointer">
              <router-link :to="{ name: 'Creatives' }">Creatives</router-link>
            </div>
          </li>
          <li>
            <div class="hover:bg-gray-200 p-1 rounded-sm cursor-pointer">
              <router-link :to="{ name: 'ACatalogs' }">Catalog</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertiserDetails",
  computed: {
    detailsHidden() {
      return this.$store.getters["detailsHidden"];
    },
  },
  mounted() {
    const { name, path } = this.$route;
    this.$el.querySelectorAll("li").forEach((li) => {
      const classList = li.querySelector("div").classList;
      if (name.includes(li.innerText) || path.includes(li.innerText.toLowerCase())) {
        classList.add("bg-publicis-200");
        classList.add("text-white");
        classList.remove("hover:bg-gray-200");
      } else {
        classList.add("hover:bg-gray-200");
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
