import Forum from "@/views/Forum/Forum.vue";
import PostDetails from "@/views/Forum/PostDetails.vue";

const forumRouters = [
  {
    path: "",
    component: Forum,
    name: "Forum",
  },
  {
    path: ":id",
    component: PostDetails,
    name: "PostDetails",
  },
];

export default forumRouters;
