<template>
  <div class="container fixed bottom-0 w-full rounded-t-lg p-4 pb-16 z-10" id="shadow">
    <div class="pb-4 flex flex-start relative">
      <div class="m-auto uppercase font-bold">
        <h1>Create new post</h1>
      </div>
      <div>
        <img
          @click="$emit('cancel')"
          id="rotate"
          class="ml-auto cursor-pointer rounded-full hover:bg-gray-200"
          src="/assets/icons/icon-plus.svg"
          alt="Close"
        />
      </div>
    </div>
    <hr />
    <div class="mt-2 flex">
      <div class="w-1/12 flex flex-col justify-center">
        <label for="title">Title</label>
      </div>
      <input
        name="title"
        placeholder="Title"
        v-model="post.title"
        type="text"
        class="appearance-none bg-transparent border rounded border-gray-300 w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none focus:border-teal-300"
      />
    </div>
    <div class="mt-2 flex">
      <div class="w-1/12 flex flex-col justify-center">
        <label for="title">Priority</label>
      </div>
      <div class="inline-block relative w-full">
        <select
          class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 p-1 rounded leading-tight focus:outline-none focus:border-teal-300"
          v-model="post.priority"
        >
          <option>Normal</option>
          <option>High</option>
          <option>Critical</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="my-2 flex">
      <div class="w-1/12 flex flex-col justify-center">
        <label for="body">Body</label>
      </div>
      <textarea
        name="body"
        placeholder="Body"
        id="post"
        class="appearance-none bg-transparent border rounded border-gray-300 w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none focus:border-teal-300"
        cols="30"
        rows="10"
        v-model="post.content"
      ></textarea>
    </div>
    <hr />
    <div class="pt-4 text-right pr-4">
      <button
        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        @click="cancel()"
      >
        Cancel
      </button>
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        @click="create()"
      >
        Create
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      post: {
        title: "",
        content: "",
        priority: "Normal",
        status: 1,
      },
    };
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) this.$emit("cancel");
    });
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    create() {
      this.$emit("create", this.post);
    },
  },
});
</script>
<style lang="scss" scoped>
textarea {
  resize: none;
}
#rotate {
  transform: rotate(45deg);
}
#shadow {
  box-shadow: 0px 25px 50px 12px rgba(0, 0, 0, 0.25);
}
</style>
