<template>
  <div
    class="block text-white cursor-pointer lg:inline-block lg:mt-0"
    id="parent"
    @mouseover="hidden = false"
    @mouseleave="hidden = true"
  >
    <router-link :to="link" v-if="!items.length">
      <div class="px-4 py-4 pr-4 hover:bg-publicis-200">
        <a>{{ this.title }}</a>
      </div>
    </router-link>
    <div v-else class="px-4 py-4 pr-4 hover:bg-publicis-200">
      <a>{{ this.title }}</a>
    </div>
    <transition name="slide-fade">
      <div
        class="absolute z-10 w-auto shadow dropdown bg-publicis-500"
        v-show="!hidden"
        id="dropdown-items"
      >
        <div>
          <div class="flex flex-col">
            <div class="py-4 hover:bg-publicis-700" v-for="item in items" :key="item.item">
              <router-link :to="item.link">{{ item.item }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array },
    title: { type: String, required: true },
    link: { required: false },
  },
  name: "NavbarDropdown",
  data() {
    return {
      hidden: true,
    };
  },
  mounted() {
    this.$el.querySelector("#dropdown-items").style.width = `${this.$el.offsetWidth}px`;
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.25s ease-in;
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
