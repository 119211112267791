<template>
  <div class="flex">
    <div class="rounded-full p-2 bg-gray-300 hover:bg-publicis-200">
      <a href="/home">
        <img src="/assets/icons/icon-home.svg" style="filter: opacity(0.5)" />
      </a>
    </div>
    <div class="flex pl-2" id="path">
      <div v-for="split in this.path" :key="split" class="split flex flex-col justify-center">
        <div class="flex">
          {{ split }}
          <img
            src="/assets/icons/icon-arrow-right.svg"
            style="filter: opacity(0.5); z-index: -1"
            class="pl-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    raw: { type: String },
  },
  mounted() {
    if (!this.raw) {
      const parts = this.$route.path.split("/").filter((x) => x.length);
      const editOrNew = parts[2] === "New" ? "Edit" : "New";
      this.path = `${this.capitalizeFirstLetter(parts[0])}, ${editOrNew} ${parts[1]}`.split(",");
    }
  },
  data() {
    return {
      path: this.raw ? this.raw.split(",") : "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss">
#path {
  &::before {
    content: "";
    background-color: black;
  }
}

.split {
  img {
    width: 20%;
  }
  &:last-child {
    img {
      display: none;
    }
  }
}
</style>
