<template>
  <div>
    <div class="border border-gray-200 rounded" v-if="loaded">
      <div class="flex p-3 text-left bg-gray-100 justify-left" v-if="mergedOptions.toolbar">
        <div v-if="mergedOptions.new" class="flex flex-col justify-center">
          <router-link :to="mergedOptions.new.link">
            <button
              class="px-2 py-1 font-bold bg-white border border-gray-200 rounded focus:outline-none focus:shadow-outline hover:bg-gray-300"
            >
              New
            </button>
          </router-link>
        </div>

        <div
          class="flex flex-col-reverse justify-center ml-4"
          v-if="mergedOptions.toolbar && mergedOptions.search"
        >
          <form @submit.prevent="search($event)" class="flex space-x-3">
            <label for="search" class="hidden"></label>
            <input
              class="block p-1 leading-normal bg-white border border-gray-200 rounded-sm appearance-none focus:outline-none focus:shadow-outline"
              id="search"
              ref="search"
              placeholder="ID/Name"
              name="search"
            />

            <button
              type="submit"
              class="px-2 py-1 bg-white border border-gray-200 rounded focus:outline-none focus:shadow-outline hover:bg-gray-300"
            >
              Search
            </button>
          </form>
        </div>

        <div v-if="checked.length > 0"></div>
        <div class="relative flex ml-auto">
          <slot name="downloadOptions" />
          <!-- Date filter-->
          <div
            id="dateOptions"
            class="px-2 py-2 ml-auto mr-8 bg-gray-400 rounded cursor-pointer hover:shadow group-hover:block"
          >
            <div class="px-3">{{ dateFilter }}</div>
            <div
              class="absolute z-10 hidden mt-2 -ml-2 bg-gray-200 rounded-b shadow group-hover:block"
              id="dateFilter"
            >
              <div
                class="px-3 py-1 hover:bg-gray-100"
                v-for="filter in filters"
                :key="filter"
                @click="changeDateFilter(filter)"
              >
                {{ filter }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-4" v-if="!datetimeChange">
        <div class="flex justify-between space-x-2" v-if="filtered_info.length > 0">
          <check-column
            class="p-2"
            :data="filtered_info"
            @check="$event.id === 'all' ? selectAll($event.e) : check()"
          />
          <column
            class="py-2"
            v-for="field in fields"
            :field="field"
            :data="filtered_info"
            :key="field.name"
          />
          <data-column
            v-show="filtered_info[0].data"
            class="hidden max-w-xl p-2 whitespace-no-wrap bg-gray-100 rounded xl:max-w-3xl lg:max-w-2xl lg:flex"
            :data="filtered_info"
          />
          <action-buttons
            class="py-2"
            :data="filtered_info"
            :options="options"
            @status="statusButtonClicked($event)"
            @refresh="changeDateFilter(dateFilter)"
            :model="model"
          />
        </div>

        <div class="flex justify-center mt-2 space-x-2" v-if="paginate">
          <!-- Insert go to before page -->
          <div
            class="px-2 py-1 bg-gray-300 rounded cursor-pointer hover:bg-gray-400"
            @click="changePage(current_page - 1)"
            v-if="current_page != 1"
          >
            <!-- eslint-disable-next-line vue/no-parsing-error -->
            {{ "<" }}
          </div>
          <div
            v-for="item in pagesRange"
            :key="item"
            class="px-2 py-1 bg-gray-300 rounded cursor-pointer hover:bg-gray-400"
            :class="{ 'bg-publicis-200 text-white': current_page === item }"
            @click="changePage(item)"
          >
            {{ item }}
          </div>
          <div
            class="px-2 py-1 bg-gray-300 rounded cursor-pointer hover:bg-gray-400"
            @click="changePage(current_page + 1)"
            v-if="current_page != totalPages"
          >
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mx-auto my-16 half-circle-spinner">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mx-auto my-16 half-circle-spinner">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Column from "@/components/DataTable/Column";
import ActionButtons from "@/components/DataTable/ActionButtons";
import DataColumn from "@/components/DataTable/DataColumn";
import CheckColumn from "@/components/DataTable/CheckColumn";

import { range } from "lodash";

export default {
  components: {
    CheckColumn,
    DataColumn,
    ActionButtons,
    Column,
  },
  props: {
    url: { type: String, required: true },
    fields: { type: Array, required: true },
    options: {
      type: Object,
      default: () => ({}),
    },
    model: { type: String },
  },
  data() {
    return {
      datetimeChange: false,
      searchTerm: "",
      original_info: [],
      mergedOptions: {},
      current_page: 1,
      checked: [],
      paginate: true,
      totalPages: NaN,
      loaded: false,
      dateFilter: "All Time",
      filters: ["All Time", "Last 7 Days", "Today", "Yesterday", "This week"],
      defaultOptions: {
        status: true,
        edit: false,
        toolbar: true,
        search: true,
        new: false,
      },
    };
  },
  computed: {
    pretty_url() {
      return new URL(this.$config.base_url + this.url);
    },
    pagesRange() {
      return this.range(this.current_page, this.totalPages);
    },
    processedFields() {
      return this.fields;
    },
    filtered_info() {
      if (this.searchTerm === "") {
        return this.original_info;
      }
      if (isNaN(this.searchTerm)) {
        return this.original_info.filter((x) => {
          const re = new RegExp(this.searchTerm, "gi");
          return x.name.match(re);
        });
      } else {
        return this.original_info.filter((x) => x.id === this.searchTerm);
      }
    },
    rowChecked() {
      return Array.from(document.querySelectorAll(".datatable-checkbox")).some((x) => x.checked);
    },
  },
  methods: {
    async changeDateFilter(filter) {
      this.dateFilter = filter;
      const formattedFilter = this.string_to_slug(filter);
      const url = this.url.includes("?")
        ? `${this.url}&timefilter=${formattedFilter}`
        : `${this.url}?timefilter=${formattedFilter}`;
      this.datetimeChange = true;

      const { data } = await this.$http.get(url);
      this.original_info = data.data;
      this.datetimeChange = false;
    },
    replacer(item, to_replace, replacer) {
      return item.split(to_replace).join(replacer);
    },
    range(current, total) {
      let start, end;
      if (current - 3 > 1) {
        start = current - 3;
        end = current + 3 > total ? total + 1 : current + 3;
      } else {
        start = 1;
        end = start + 3 > total ? total + 1 : current + 3;
      }
      return range(start, end);
    },
    async changePage(page) {
      const url = this.pretty_url;
      url.searchParams.append("page", page);

      const { data } = await this.$http.get(url.href);
      this.original_info = data.data;
      this.current_page = data.current_page;

      url.searchParams.append("wd", true);
      const { data: withData } = await this.$http.get(url.href);
      this.original_info = withData.data;
      this.current_page = withData.current_page;
    },
    selectAll(e) {
      document
        .querySelectorAll('input[type="checkbox"]')
        .forEach((x) => (x.checked = e.target.checked));

      this.checked = Array.from(document.querySelectorAll("input:checked"))
        .filter((x) => x.dataset.id)
        .map((x) => parseInt(x.dataset.id));
    },
    check() {
      this.checked = Array.from(document.querySelectorAll("input:checked"))
        .filter((x) => x.dataset.id)
        .map((x) => parseInt(x.dataset.id));
    },
    statusButtonClicked(line) {
      this.$http.post(this.mergedOptions.status.link.replace("$id$", line.id));
      line.status = line.status === 1 ? 0 : 1;
    },
    async search() {
      this.searched = true;
      this.datetimeChange = true;
      const search = this.$refs.search.value;
      if (!search.length) {
        if (this.saved_info) {
          this.original_info = this.saved_info.slice();
          this.paginate = this.save_paginate;
          this.datetimeChange = false;
        }
      } else {
        const url = this.pretty_url;
        url.searchParams.set("q", search);
        const { data } = await this.$http.get(url.href);
        this.saved_info = this.original_info.slice();
        this.save_paginate = this.paginate ? true : false;
        this.original_info = data;
        this.paginate = false;
        this.datetimeChange = false;
      }
    },
  },
  async created() {
    Object.assign(this.mergedOptions, this.defaultOptions, this.options);
    const { data } = await this.$http.get(this.url);

    this.original_info = data.data;
    this.current_page = data.current_page;

    this.loaded = true;

    this.paginate = data.total >= data.per_page;
    this.pages = data.last_page < 4 ? data.last_page : 4;
    this.totalPages = data.last_page;

    const url = new URL(this.$config.base_url + this.url);
    if (this.mergedOptions.data) {
      url.searchParams.append("wd", "true");
      const { data } = await this.$http.get(url);
      if (!this.searched) this.original_info = data.data;
    }
  },
};
</script>

<style lang="scss" scoped>
#dateOptions:hover #dateFilter {
  display: block;
}

// .page:not(:last-of-type) {
//   margin-right: 0.5rem;
// }

.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
